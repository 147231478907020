/* You can add global styles to this file, and also import other style files */
//// Load material-icons
@use '@angular/material' as mat;
@import "material-3-theme.scss";

:root {
  --mat-menu-container-shape: .75rem;
  --mat-menu-container-color: #{mat.get-theme-color($primus-m3, surface-container-low)};
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url(./primus-assets/fonts/material-symbols-outlined.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto Flex';
  src: url('./primus-assets/fonts/roboto-flex.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./primus-assets/fonts/material-icons.woff2) format('woff2');
}

/* source: https://fonts.googleapis.com/icon?family=Material+Icons */
/* fallback */
.legacy-icons {
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
}

@layer legacy, m3;
@layer legacy {

  // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
  //  The following line adds:
  //    1. Default typography styles for all components
  //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
  //  If you specify typography styles for the components you use elsewhere, you should delete this line.
  //  If you don't need the default component typographies but still want the hierarchy styles,
  //  you can delete this line and instead use:
  //    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
  //@include mat.typography-hierarchy(mat.m2-define-typography-config(
  //  $font-family: 'Roboto Flex',
  //));
  //@include mat.core();

  /**************custom color themes explanation*************************/
  //Primary - Main colors most widely used across all screens and components.
  //Accent - Also known as the secondary color. Used for floating action buttons and interactive elements.
  //Warn - Colors to convey error state.
  //Foreground - Used for text and icons.
  //Background - Colors used for element backgrounds.
  // mat.define-palette(palette, default, lighter, darker)
  /************************************************************/
  $primary: mat.m2-define-palette(mat.$m2-light-blue-palette, 800, 600, 900);
  $accent: mat.m2-define-palette(mat.$m2-light-blue-palette, A400);
  $warn: mat.m2-define-palette(mat.$m2-deep-orange-palette, A700);
  $theme: mat.m2-define-light-theme($primary, $accent, $warn);


  //@include mat.all-component-themes($theme);
  @mixin set-active($color-theme, $color) {
    background: mat.m2-get-color-from-palette($color-theme, $color);
    border-color: mat.m2-get-color-from-palette($color-theme, $color);
    color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
  }

  .mat-mdc-unelevated-button.mat-mdc-button-base[aria-expanded="true"],
  .mat-flat-button.mat-button-base.active,
  .mat-mdc-outlined-button.mat-button-base[aria-expanded="true"],
  .mat-stroked-button.mat-button-base.active {
    @include set-active(mat.$m2-gray-palette, 400);
  }

  .mat-mdc-unelevated-button.mat-primary[aria-expanded="true"],
  .mat-flat-button.mat-primary.active,
  .mat-mdc-outlined-button.mat-primary[aria-expanded="true"],
  .mat-stroked-button.mat-primary.active {
    @include set-active(mat.$m2-indigo-palette, 500);
  }

  .mat-mdc-unelevated-button.mat-accent[aria-expanded="true"],
  .mat-flat-button.mat-accent.active,
  .mat-mdc-outlined-button.mat-accent[aria-expanded="true"],
  .mat-stroked-button.mat-accent.active {
    @include set-active(mat.$m2-light-blue-palette, A700);
  }

  .mat-mdc-unelevated-button.mat-warn[aria-expanded="true"],
  .mat-flat-button.mat-warn.active,
  .mat-mdc-outlined-button.mat-warn[aria-expanded="true"],
  .mat-stroked-button.mat-warn.active {
    @include set-active(mat.$m2-deep-orange-palette, 400);
  }

  .mat-mdc-unelevated-button.mat-accent {
    color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
  }

  .mat-mdc-unelevated-button.mat-accent:not([disabled]) {
    &:active, &:hover, &:focus {
      @include set-active(mat.$m2-light-blue-palette, A700);
    }
  }

  .mat-mdc-button.mat-mdc-button-base[aria-expanded="true"],
  .mat-button.mat-button-base.active {
    color: mat.m2-get-color-from-palette($accent, default) !important;

    .mat-icon, span {
      color: mat.m2-get-color-from-palette($accent, default) !important;
    }
  }

  body.no-fixed-height {
    height: unset !important;
    width: 794px !important;
    margin-top: -20px !important;
    overflow: hidden;
  }

  #endOfReport {
    display: none; /* Hide this element when rendering for PDF */
  }

  .white-bg-in-checkbox {

    --mdc-checkbox-selected-checkmark-color: mat.get-theme-color($primus-m3, on-surface);

    .mdc-checkbox .mdc-checkbox__background {
      background-color: mat.get-theme-color($primus-m3, surface-container-lowest)!important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-frame {
      background-color: mat.get-theme-color($primus-m3, outline-variant);
    }

  }

  .white-bg-in-radio-button {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-outer-circle {
      background-color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
    }
  }


  .mat-mdc-header-row {
    background-color: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 50);

    th {
      &.sorting-enabled {
        &:not(.active) {
          &:active, &:hover, &:focus {
            background-color: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 100);
          }
        }

        &.active {
          background-color: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 100);
        }
      }
    }
  }

  .mat-mdc-option.mat-active,
  .mat-option:hover:not(.mat-option-disabled) {
    background-color: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 50);
  }

  .predicate-group-color {
    background: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 50);
  }

  button.mat-mdc-menu-item {
    &.active, &:hover:not([disabled]) {
      background: mat.m2-get-color-from-palette($accent, lighter);
    }
  }

  .mat-expansion-panel.mat-expanded:not(.no-active-blue-color, #faq-expansion),
  .mat-expansion-panel:not(.mat-expanded, #faq-expansion):not(.no-active-blue-color) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: mat.m2-get-color-from-palette($accent, lighter);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-active {
    opacity: 1 !important;
  }

  .faq-page,
  .admin {
    background: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
  }

  /**********************colors*************************************/
  .error-warn-bg-color {
    background-color: mat.m2-get-color-from-palette(mat.$m2-deep-orange-palette, 100);
  }

  .error-warn-border-color {
    border-color: mat.m2-get-color-from-palette(mat.$m2-deep-orange-palette, A700);
  }

  .error-warn-color {
    color: mat.m2-get-color-from-palette(mat.$m2-deep-orange-palette, A700);
  }

  .normal-bg-color {
    background: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 100);
  }

  .bg-blue {
    background-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, A400) !important;
  }

  .bg-blue-hover {
    &:active, &:hover, &:focus {
      background-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, A400);
    }
  }

  .bg-primary {
    background-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800);
  }

  .bg-primary-hover {
    &:active, &:hover, &:focus {
      background-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800);
    }
  }

  .bg-white {
    background-color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
  }

  .light-gray-bg-color {
    background: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 50);
  }

  .border-blue {
    border-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, A400) !important;
  }

  .border-primary {
    border-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800) !important;
  }

  .text-blue {
    color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, A400) !important;
  }

  .text-blue-hover:not([disabled]) {
    &:active, &:hover, &:focus {
      color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, A400) !important;
    }
  }

  .text-primary {
    color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800);
  }

  .text-primary-hover:not([disabled]) {
    &:active, &:hover, &:focus {
      color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800) !important;
    }
  }

  .text-dark-primary {
    color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 900);
  }

  .text-dark-primary-hover:not([disabled]) {
    &:active, &:hover, &:focus {
      color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 900) !important;
    }
  }

  .color-white {
    color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
  }


  .blue-hover-icon {
    height: 40px !important;
    width: 40px !important;
    font-size: 14px !important;

    &:active, &:hover, &:focus {
      i, .mat-icon {
        color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800) !important;
      }
    }
  }

  .light-bg-blue {
    background-color: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 50);
  }

  /********************************************************/
  .edit-field-selected-options {
    color: mat.m2-get-color-from-palette(mat.$m2-indigo-palette, 900);

    &:hover, &:active, &:focus {
      .button-overlay {
        opacity: .04;
        background-color: mat.m2-get-color-from-palette(mat.$m2-indigo-palette, 900);
      }
    }
  }

  .media-carousel__size-buttons {
    color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800);

    &.active {
      background-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, 800) !important;
      color: white !important;
    }
  }

  .change-image-order__drop-down-item {
    &:hover, &:active, &:focus, &.active {
      background: mat.m2-get-color-from-palette($accent, lighter);
    }
  }

  .edit-field-select-hierarchy-node {
    &:hover, &:active, &:focus {
      .selectedChild-button {
        background-color: mat.m2-get-color-from-palette($accent, lighter);
      }
    }
  }

  .edit-field-select-hierarchy-node .selectedRow,
  .node-level-1 .selectedNodeRow,
  .node-level-child .selectedChildNodeRow,
  .field-select-options__option.selectedRow,
  .field-select-options__option.selectedOption {
    background-color: mat.m2-get-color-from-palette($accent, lighter);
  }

  .edit-field-select-hierarchy-node__node,
  .field-select-options__option {
    &:hover, &:active, &:focus {
      background-color: mat.m2-get-color-from-palette($accent, lighter);
    }
  }

  .object-menus-small__dropdown {
    li {
      &:hover, &:active, &:focus {
        .obj-menus-commons-menuGroup-btn {
          background-color: mat.m2-get-color-from-palette($accent, lighter);
        }
      }
    }

    .activeMenuWithSub {
      li {
        &:hover, &:active, &:focus {
          background-color: mat.m2-get-color-from-palette($accent, lighter);
        }
      }
    }
  }

  .filter-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-frame {
      border-color: #999;
    }

    .mat-mdc-checkbox {
      display: block;

      &:active, &:hover, &:focus {
        font-family: 'Roboto Flex', sans-serif !important;
        font-weight: 700;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-layout,
    .mat-checkbox-label {
      width: 100%;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-label {
      overflow: hidden;
    }
  }

  .gray-radio-button {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-outer-circle {
      border-color: #999;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    &.mat-radio-disabled {
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
      .mat-radio-outer-circle {
        background-color: #999;
        border-color: #999;
      }
    }
  }

  /****************************************/
  .view-bg-color-75 {
    background: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 50);
  }

  .view-hover-color {
    &:active, &:hover, &:focus {
      background: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 100);
    }
  }

  .view-bg-color-200 {
    background: mat.m2-get-color-from-palette(mat.$m2-blue-grey-palette, 200);
  }

  /*******************************************************************/
  .mat-form-field-appearance-outline {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-outline {
      background-color: white;
      border-radius: 3px !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-outline-start {
      border-radius: 3px 0 0 3px !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-outline-end {
      border-radius: 0 3px 3px 0 !important;
    }
  }

  /*******************************************************************/
  .small-mat-form-outline {
    padding-right: 10px;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex > .mat-form-field-infix {
      padding-top: 0.2em !important;
      padding-bottom: 0.67em !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: 0.2em;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label-wrapper {
      top: -1.6em;
      padding-top: 1.6em;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-outline {
      top: 0;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.2em) scale(.75);
      width: 133.33333%;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-flex {
      margin-top: 0;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline ::ng-deep .mat-form-field-outline-thick {
      height: 37px;
    }
  }

  .clear-icon,
  .search-icon {
    display: inline-block !important;
  }

  .clear-icon {
    font-size: 12px !important;
    margin-right: 0 !important;
    color: #999;
  }

  .media-list__files.active {
    .media-list__files-img-container,
    .media-list__files-content {
      border-color: mat.m2-get-color-from-palette(mat.$m2-light-blue-palette, A400, 0.5) !important;
    }
  }

  /*************************dialog***************************************/
  $dialog-primary: mat.m2-define-palette(mat.$m2-brown-palette, 700, 50);
  $dialog-accent: mat.m2-define-palette(mat.$m2-orange-palette, 800);
  $dialog-theme: mat.m2-define-light-theme($dialog-primary, $dialog-accent, $warn);

  .dialog {
    @include mat.all-component-themes($dialog-theme);
    background: #fffaf6;
    border-color: mat.m2-get-color-from-palette($dialog-primary, lighter);

    .mat-mdc-unelevated-button.mat-primary[aria-expanded="true"],
    .mat-flat-button.mat-primary.active,
    .mat-mdc-outlined-button.mat-primary[aria-expanded="true"],
    .mat-stroked-button.mat-primary.active {
      @include set-active(mat.$m2-brown-palette, 400);
    }

    .mat-mdc-unelevated-button.mat-accent[aria-expanded="true"],
    .mat-flat-button.mat-accent.active,
    .mat-mdc-outlined-button.mat-accent[aria-expanded="true"],
    .mat-stroked-button.mat-accent.active {
      @include set-active(mat.$m2-deep-orange-palette, 800);
    }

    .mat-primary.active {
      background: mat.m2-get-color-from-palette($dialog-primary, lighter);
    }

    .mat-mdc-unelevated-button.mat-accent:not([disabled]) {
      @include set-active(mat.$m2-orange-palette, 800);

      &:active, &:hover, &:focus {
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-focus-overlay {
          opacity: 0.04;
        }
      }
    }

    .mat-mdc-outlined-button {
      font-size: 13px;
      line-height: 26px;
      border-color: mat.m2-get-color-from-palette($dialog-primary, lighter);
    }

    .mat-mdc-outlined-button.mat-primary[aria-expanded="true"] {
      color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
    }

    .dialog-front-color {
      background: mat.m2-get-color-from-palette($dialog-primary, lighter);
    }

    .dialog-accent-color {
      color: mat.m2-get-color-from-palette($dialog-accent, default);
    }

    .dialog-primary-color {
      color: mat.m2-get-color-from-palette($dialog-primary, default);
    }

    .dialog-accent-bg-color {
      background: mat.m2-get-color-from-palette($dialog-accent, default);
    }

    .dialog-border-color {
      border-color: mat.m2-get-color-from-palette($dialog-primary, lighter) !important;
    }

    .edit-field-selected-options {
      color: mat.m2-get-color-from-palette($dialog-primary, default);

      &:hover, &:active, &:focus {
        .button-overlay {
          opacity: .04;
          background-color: mat.m2-get-color-from-palette($dialog-primary, default);
        }
      }
    }

    .operation-dialog-step-indicator-bg {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 50);
      border: 1px solid mat.m2-get-color-from-palette(mat.$m2-brown-palette, 700)
    }

    .operation-dialog-step-indicator-bg-active {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 700);
    }

    .edit-field-inline-array-item__top_bar {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 50) !important;

      &:hover, &:focus, &:active, &.active {
        background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100) !important;
      }
    }

    .edit-field-inline-array-item__content {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 50) !important;
    }

    .media-list__files-img-container,
    .media-list__files-content,
    .media-list__files-img-container__annotate-button {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100, 0.2) !important;
      border-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100) !important;
    }

    .media-list__files.active {
      .media-list__files-img-container,
      .media-list__files-content {
        background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100, 0.5) !important;
        color: #333 !important;
        border-color: mat.m2-get-color-from-palette($dialog-accent, default, 0.5) !important;
      }

      .media-list__files-img-container__annotate-button {
        background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100, 0.5) !important;
        color: #333 !important;
      }
    }

    .cdk-drag-dragging, .cdk-drag-preview {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 50) !important;
    }

    .mat-mdc-header-row {
      background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 50);

      th {
        &.sorting-enabled {
          &:not(.active) {
            &:active, &:hover, &:focus {
              background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100);
            }
          }

          &.active {
            background-color: mat.m2-get-color-from-palette(mat.$m2-brown-palette, 100);
          }
        }
      }
    }

    .annotation-dashboard-buttons__cancel {
      color: mat.m2-get-color-from-palette($dialog-accent, default) !important;
    }

    .mat-mdc-header-cell {
      border-bottom-color: #E6E6E6;
      color: initial;
    }
  }

  /***************************************/
  .admin-settings-setting, .admin-settings-faq {
    .mat-mdc-card-header {
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      .mat-card-header-text {
        flex-grow: 1;
        margin: 0;
      }
    }

    .mat-mdc-card-header .mat-mdc-card-title {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline {
      background-color: #cfd8dc;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
      border-top-color: #E8EAED;
    }
  }

  /************************************/
  .mat-mdc-menu-panel.leave-focus-menu {
    background-color: #576062; //$focus-menu-button;
    border: 1px solid #28383e; //$focus-menu-button-border;
    border-radius: 0;
    min-height: auto;

    button {
      color: white;
      font-size: 12px;
      width: 100%;
    }
  }

  .mat-mdc-menu-panel.common-operation-menu-styling__menu {
    --mat-menu-container-shape: .75rem;
    --mat-expansion-container-shape: .75rem;
    width: 400px;
    max-width: initial;
    margin-left: -36px;
    @media only screen and (max-width: 850px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    .mat-expansion-panel-header {
      font-size: 13px;
    }

    .mat-expansion-panel-body {
      padding: 0 0 16px !important;
    }

    .mat-mdc-list-base {
      padding-top: 0;
    }
  }

  .mat-mdc-list.common-search-menu {
    padding-top: 0;
    min-width: 204px;
  }

  .mat-mdc-list.overview-show-more-menu {
    padding-top: 0;

    .mat-mdc-list-item {
      height: 36px;
      line-height: 36px;
      font-size: 13px;
    }
  }

  .mat-mdc-menu-panel.overview-show-more-menu-panel {
    min-height: auto;
  }

  /*************************************/
  .search-input-menu-drop-down {
    .mat-mdc-menu-item {
      font-size: 13px;

      .mat-icon {
        margin-right: 5px;
      }
    }

    .mat-mdc-menu-content {
      min-width: 240px;
    }

    &__icon {
      &.mat-icon {
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 16px;
      }
    }

  }

  .search-input-menu-trigger {
    .mat-mdc-menu-trigger {
      &.active,
      &.isButton,
      &:hover {
        background: mat.m2-get-color-from-palette($accent, default);

        .vertical-line {
          height: 100% !important;
          background-color: mat.m2-get-color-from-palette($accent, default) !important;
        }

        span, i {
          color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100);
        }
      }

      &.notButton {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  // The d-block class must be defined here else bootstrap modals are invisible when opened
  .d-block {
    display: block !important;
  }

  /****Dragula: drag and drop***/
  /* in-flight clone */
  .gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }

  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }

  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }

  /**
   * Styles applied to the element being dragged when building
   * and advanced-search-query.
   * This element is applied directly to <body> and wil thus be outside the
   * CSS-scope of the advanced-search-component.
   * TODO: Move to external file and import (?)
   */
  .advanced-search-drag-preview {

    &.cdk-drag-preview {
      min-width: 200px;
      height: 50px;
      border: 1px dashed #CCC;
      border-radius: 5px;
      color: #000;
      padding: 5px 10px;
      background-color: #FEFEFE;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

  }


  .search-page .user-profile {
    top: 0 !important;
  }

  // Fixes padding issue on remove button of mat-chip
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  mat-chip > mat-icon {
    padding-right: 30px !important;
  }

  .media-selector-general__snackbar-panel-information {
    margin: 8px 8px 40px 8px !important;
  }

  .media-selector-general__snackbar-panel-success {
    margin: 8px 8px 40px 8px !important;

    div.mdc-snackbar__surface {
      background-color: #2e7d32 !important;
    }
  }

  .object-edit-new__snackbar-panel {
    margin: 8px 8px 40px 8px !important;

    div.mdc-snackbar__surface {
      background-color: #2e7d32 !important;
    }
  }

  //.edit-dialog {
  //  width: 80vw;
  //}
  //
  body {
    overflow: auto !important;
    position: static !important;
  }

  // utilities
  .p-2 {
    padding: 0.5rem;
  }


  .highlighted-extended-search-tree-view {
    background-color: #C9ECF6;
  }

  .helper-text {
    color: #686868;
    font-size: 0.8125rem;
    font-weight: 400;
  }

  .faq-sidebar {
    max-width: 425px;
  }

  .app-root__drawer-container {
    flex: 1 1 auto;
  height: 100%;
  }

  // Must be global to target the portal far away from the originating component
  .object-page-header__information-popover-mat-menu {
    max-width: 600px !important;
  }
}

/* Global print styles for PDF generation */
@page {
  margin-top: 1cm;
  margin-right: 1cm;
  margin-bottom: 1cm;
  margin-left: 1cm;
  size: A4;
}

///////////////////////////////////////////////////////////////////////////////
///////////// NEW STYLES AND TEMPORARY OVERRIDES FOR NEW APP LAYOUT ///////////
////////////// TO BE MOVED OR REMOVED WHEN OLD LAYOUT IS RETIRED //////////////
//////////////////////////////////////// (•_•) ////////////////////////////////
//////////////////////////////////////// ( •_•)>⌐■-■ //////////////////////////
//////////////////////////////////////// (⌐■_■) ///////////////////////////////
///////////////////////////////////////////////////////////////////////////////
#app-component {

  .template-model__overlay,
  .template-model__edit {
    top: 0!important;
  }

  .object-content-list__top-button-filter {
    display: flex;
  }

  .reports-content {
    flex: 1;
    display: flex;
    min-height: 0;
    overflow: hidden;
  }

  report-generated,
  report-templates {
    flex: 1;
    overflow: auto;
    padding-right: 1rem;
    &::-webkit-scrollbar {
      width: .5rem;
      height: .5rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: var(--scrollbar-thumb-radius);
      background-color: mat.get-theme-color($primus-m3, outline-variant);
    }
    &::-webkit-scrollbar-track-piece {
      margin-top: .75rem;
    }
  }

  .new_report_sidemenu {
    height: 100%;
    max-height: 100%;
  }

  edit-report {
    flex: 1;
    display: flex;
  }

  .edit-report-template-container {
    flex: 1;
  }

  .edit-report-button-area{
    left: 497px;
  }

  .edit-report-template-container-generated .edit-report-button-area {
    left: 72px;
  }

  .edit-report-template-container-right {
    background: mat.get-theme-color($primus-m3, outline);
    max-height: unset;
    height: 100%;
    overflow: auto;
}

  .edit-report-template-container-left {
    background-color: mat.get-theme-color($primus-m3, surface);
    padding: 1.5rem;
    height: 100%;
    overflow: auto;
}

  .reports-index-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .dropdown-menu-animated__button-text {
    white-space: nowrap;
  }

  .search-query__search-count {
    display: none;
  }

  .extended-search-query {
    padding-left: 0rem !important;
  }

  .query-builder {
    padding: 0.1875rem 1rem 1rem 0;
  }

  app-admin-users-profile-page .user-profile {
    height: 100%;
  }

  .admin-address {
    display: flex;
    flex: 1;
    height: unset;
    overflow: hidden;
  }

  .admin-collection {
    flex: 1;
    height: unset;
  }

  .admin-header-container {
    left: 71px;
    top: 0;
  }

  .admin-location-wrapper {
    flex: 1;
    height: unset;
  }

  .admin-mass-media-import {
    display: flex;
    flex: 1;

    &__container {
      min-height: unset;
    }
  }

  .admin-named-object {
    display: flex;
    flex: 1;
    height: unset;
    overflow: hidden;
  }

  .category-selector__container {
    padding-top: .5625rem;
    padding-top: 0; //work in progress
  }
  .category-selector__header {
    padding-bottom: .9375rem;
    display: none;
  }

  .clear-input-field {
    position: unset;
    right: unset;
    top: unset;
    height: unset;
    width: unset;
    display: flex;
  }

  app-filter-menu-filter-list {
    .mat-expansion-panel:not(:last-of-type) {
      border-bottom: 1px solid var(--sys-surface);
    }
  }

  .filter-menu__menu-toggle-button {
    display: block;
  }

  div.search-query__new-layout.row {

      height: unset;
      width: unset;
      display: flex;
      flex: 1;
      gap: .5rem;
      align-items: center;

      input[type="text"] {
        min-height: 1.25rem;
        border: none;
        margin: 0;
        height: unset;

        font: mat.get-theme-typography($primus-m3, body-medium);
        letter-spacing: mat.get-theme-typography($primus-m3, body-medium, letter-spacing);

        padding: .75rem 1rem;
      }

      &::before,
      &::after {
        display: none;
      }

    }

  .search-query__new-layout .search-query__input {
      min-width: 100px;
      min-height: 44px;
      width: unset;
      float: unset;
      height: unset;
      display: flex;
      flex: 1;
      background-color: var(--sys-surface-container-high);
      padding-inline: 1rem;
      border-radius: 99px;
      max-width: 1024px;
  }

  .search-query__input input {
    background-color: var(--sys-surface-container-high);
  }


  div.search-query__input {
    margin-right: 0;
  }

  #searchQueryNew::placeholder {
    color: var(--placeholder-text-color);
    opacity: .8;
  }

  form.search-query {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .search-query__new-layout .search-query-inline-menu {
    width: unset;
    float: unset;
    height: unset;
    max-width: unset;
}

  .search-query-inline-menu {
    margin-left: auto;
    flex: 0 1 220px;
    min-width: 150px;


    button {
      text-align: left;
    }
  }

  button.search-query-inline-menu__button {
    max-width: 100%;
  }

  .search-query-inline-menu__button-content-text {
    overflow: hidden;
  }

  .search-query-inline-menu__button-content-text > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-query-inline-menu__button-content-text span:nth-child(2) {
    display: none; //hide the search count
  }

  span.search-icon {
    position: unset;
  }

  .search-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter-menu {
    background-color: var(--sys-surface);
  }

  .filter-menu .filter-menu__top-row {
    padding: 0.5rem 0.75rem;
}

  .filter-list__surface {
    background-color: var(--sys-surface-container);
  }

  .overlay {
    top: 0;
  }

  .searchHeader {
    position: unset;
    flex: 1;
  }

  .searchHeader-fixed {
    background-color: unset;
    flex: 1;
    display: flex;
    height: unset;
    padding-block: .5rem;
  }

  .searchHeader__search-query {
    height: unset;
    float: unset;
    width: unset;
    flex: 1;
    gap: .5rem;
  }

  .searchHeader__search-query__content {
    border-bottom: none;
    width: unset;
    float: unset;
    height: unset;
    flex: 1 1 auto;
    display: flex;
  }

  .searchHeader__search-query__template-button-container {
    width: unset;
    float: unset;
    height: unset;
    background-color: unset;
    display: flex;
    align-items: center;
  }

  .searchHeader__search-query__template-button-content {
    width: unset;
    height: unset;
    display: flex;
    overflow: hidden;
    border-radius: 1rem;
  }

  .search-query-inline-menu {
    .vertical-line {
      display: none;
    }
  }

  .template-group-select-button__template-vertical-line {
    display: none;
  }

  .template-group-select-button__text {
    padding: .5rem;
    max-width: unset;
  }

  app-template-group-select-button {
    max-width: 180px;
  }

  .template-group-select-button {
    background-color: var(--sys-surface-container-high);
    border: none;
    border-radius: 1rem;
  }

.template-group-select-button.hasTemplate {
  background-color: var(--sys-secondary-container);
  border: none;
}

  .template-group-select-button__choose-template {
    display: none;
  }

  .search-view-selector {
    top: 0;
    background-color: mat.get-theme-color($primus-m3, surface);
  }

  button.search-view-selector__close mat-icon.mat-icon {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .search-view__menus-container {
    padding: .25rem 0;
    border-bottom: none;
  }

  div.search-query__old-layout {
    flex: 1;
    .search-icon {
      padding-left: .75rem;
    }
    input[type=text] {
      padding-left: 3rem;
      height: unset;
      padding-block: 1rem;
    }
    a.clear-input-field {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }
  }

  .search-query-pop-up-menu-button .search-icon-arrow {
    left: -8px;
    top: 1px;
  }

  input#searchQueryOld {
    border: none;
    border-radius: 5rem;
  }


  app-search-query-extended-search {
    flex: 1;
    max-width: 1024px;
  }

  app-extended-search-query-builder .query-builder {
    border-bottom: 0;
  }

  app-search-filter-chiplist {

    div.scroll-x {
      padding: 0;
      margin-bottom: .5rem;
    }
  }

  app-search-filter-menu-small {
    margin-right: .5rem;
}

  app-search-header {
    background-color: mat.get-theme-color($primus-m3, surface);
    display: flex;
    margin-right: 1rem;
    --placeholder-text-color: var(--sys-on-surface-variant);
  }

  app-procedure {
    flex: 1;
    display: flex;
    app-search-collapse {
      display: none;
      }
    .procedure {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: unset;
    }
    .procedure__content {
        height: unset;
        flex: 1;
        .procedure__lower {
          height: unset;
          flex: 1;
        }
      }
  }

  app-search-page {
    flex: 1;
    display: flex;
    min-height: 8rem;
    app-information {
      .information__content,
      .information__lower {
        background-color: mat.get-theme-color($primus-m3, surface);
      }
      .information__lower {
        flex: 1;
        display: flex;
      }
      .information__lower-content {
        left: unset;
        flex: 1;
        display: flex;
        padding: 1.5rem;
      }
      .landing-page-top {
        background-color: mat.get-theme-color($primus-m3, surface-container-low);
        border: none;
      }
      .template-group-select-button {
        background-color: transparent;
        background-color: mat.get-theme-color($primus-m3, surface-container-low);
        border-radius: 0;
      }
      flex: 1;
      display: flex;
      min-height: 8rem;
      .information {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: unset;
        min-height: 8rem;
        div.information__vertical-menu,
        app-search-collapse{
          display: none;
        }
      }
    }
    button.object-navigator__prevNext-button {
      display: none!important;
    }
  }

  app-search-view-full-v2 {
    --mat-sidenav-container-width: 300px;
  }

  app-search-view-selector div#searchMainContent {
    --mdc-outlined-button-outline-color: #{mat.get-theme-color($primus-m3, outline-variant)};
  }

  .search-result-view-list-selector {
    .mat-mdc-radio-button {
        width: 2.5rem;
    }
}

  app-side-menu-toggle {
    display: none;
  }

  // Apply a left border to app-search-page only when it contains one of the specified pages as a direct child:
  //app-search-page:has(> app-search-view-full-v2),
  //app-search-page:has(> app-object-page-v2) {
  //   border-left: 1px solid mat.get-theme-color($primus-m3, outline-variant);
  //}

  app-object-page-v2 {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    background-color: mat.get-theme-color($primus-m3, surface);

    .header,
    .content .mat-drawer-container {
      background-color: mat.get-theme-color($primus-m3, surface);
    }

    app-object-page-layout {
      flex: 1 1 auto;
      overflow: hidden;
      height: unset; //override, remove when retiring old layout
      max-width: unset; //override, remove when retiring old layout
    }

    .object-view-field-values__title-values app-object-view-field-value {
      font: mat.get-theme-typography($primus-m3, label-medium);
      letter-spacing: mat.get-theme-typography($primus-m3, label-medium, letter-spacing);
      color: mat.get-theme-color($primus-m3, on-surface-variant);
    }

  }

  app-admin-address {
    display: flex;
    flex: 1;
  }

  app-about-primus {
    overflow: auto;
  }

  app-admin-collection {
    display: flex;
    flex: 1;
  }

  app-admin-id-format {
    flex: 1;
  }

  app-admin-location {
    display: flex;
    flex: 1;
  }

  app-admin-main-page {
    overflow: auto;
  }

  app-admin-mass-media-import {
    display: flex;
    flex: 1;
  }

  app-admin-named-object {
    display: flex;
    flex: 1;
    min-width: 0;
  }

  app-admin-place {
    display: flex;
    flex: 1;
    min-width: 0;

    section.header-and-description-wrapper>article {
      min-width: 0;
    }

    .admin-place-wrapper__content {
      min-width: 0;
    }

    .admin-place-wrapper.content-wrapper {
      flex: 1 1 35%;
    }

    .admin-place-wrapper {
      flex: 1;
      height: unset;
      min-width: 0;
      overflow: hidden;
      display: flex;
    }
  }

  app-admin-location {
    display: flex;
    flex: 1;
    min-width: 0;

    .admin-location-wrapper {
      display: flex;
      flex: 1;
      height: unset;
      min-width: 0;
      overflow: hidden;

      &__content {
        flex: 1 1 35%;
        min-width: 0;
      }
    }

    .content-wrapper {
      min-width: 0;
    }
  }

  app-admin-service-api {
    overflow: auto;
  }

  app-admin-template-group {
    flex: 1;
  }

  app-admin-settings {
    flex: 1 1 auto;
    overflow: auto;
  }

  .primus-solr-table-container {
    background-color: white;
    height: unset;
    width: unset;
  }

  app-admin-users {
    flex: 1;
    display: flex;
    min-width: 0;

    .admin-table-select-all-name {
      display: none;
    }

    .admin-place-wrapper__solr-data-table {
      width: unset;
      height: unset;
      float: unset;
    }

    .admin-users {
      height: unset;
      width: unset;
      display: flex;
      flex: 1;
      min-width: 0;
    }

    .admin-users__content {
      display: flex;
      flex-direction: column;
    }

    .admin-content-top {
      width: unset;
      height: unset;
      padding-block: .5rem;
    }

    section.header-and-description-wrapper {
      height: unset;
      padding: 2rem;

      .admin-users__primus-table {
        display: flex;
        flex-direction: column;
        height: unset;
        min-width: 0;
        min-height: 0;
        flex: 1;

        .primus-table-wrapper {
          height: unset;
        }
      }

    }

  }

  app-media-page {
    display: flex;
    flex: 1 1 auto;
  }

  app-object-new-page {
    flex: 1;
    overflow: auto;
  }

  app-object-page-layout {
    flex: 1 1 auto;
    height: unset !important;
  }

  app-admin-concept-lists {
    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 0;
  }

  .admin-concept-lists {
    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 0;
    height: 100%;
  }

  app-top-header .admin-header-container {
    flex: 1;
    min-width: 0;
    min-height: 0;
  }

  app-header-and-description {
    display: flex;
    min-width: 0;
    min-height: 0;
    flex: 1;

    h5 {
      margin: 0 0 2rem 0;
    }
  }

  section.header-and-description-wrapper {
    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 2rem;

    header {
      display: flex;
      flex: 0;
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 0;
    }

    header div.description div.description__vertical-line.bg-blue {
      display: none;
    }

    h3 {
      font: mat.get-theme-typography($primus-m3, title-large);
      letter-spacing: mat.get-theme-typography($primus-m3, title-large, letter-spacing);
      color: mat.get-theme-color($primus-m3, on-surface);
    }

    h5 {
      font: mat.get-theme-typography($primus-m3, body-medium);
      letter-spacing: mat.get-theme-typography($primus-m3, body-medium, letter-spacing);
      color: mat.get-theme-color($primus-m3, on-surface-variant);
      max-width: 100ch;
    }
  }

  app-admin-main-page {
    .mat-mdc-card.admin-card .mat-mdc-list-item {
      height: unset;
      padding: 0 0 .5rem 0;
    }
    a.admin-main-page__list-menu-item.blue-hover-icon {
      display: grid;
      grid-template-columns: 32px 1fr;
    }
  }

  app-admin-main-page,
  app-admin-create-new,
  .admin-create-new {
    background-color: mat.get-theme-color($primus-m3, surface);
  }

  app-admin-main-page,
  app-admin-create-new {
    section.header-and-description-wrapper .admin-row {
      margin-bottom: .5rem;
    }
  }

  app-admin-create-new {
    .mat-mdc-card.admin-card .mat-mdc-list-item {
      height: unset;
      padding: 0;
  }
    section.header-and-description-wrapper .admin-row {
      display: flex;
      flex-wrap: wrap;
      align-items: normal;
      justify-content: normal;
      flex-wrap: wrap;
      margin-left: unset;
      mat-card.admin-create-new__main-menu-card {
        min-width: clamp(290px, 13vw, 480px);;
      }
    }
    .admin-create-new__main-menu-card mat-list.mat-mdc-list.mat-mdc-list-base.mdc-list {
      columns: 20rem;
    }
    .admin-create-new__last-created_item {
      margin-bottom: .5rem;
    }
    .admin-create-new__last-created-object-name {
      margin-right: .5rem;
    }
    app-object-url a {
      color: mat.get-theme-color($primus-m3, primary);
    }
  }

  app-admin-main-page,
  app-admin-create-new,
  app-about-primus {
    > div {
      max-width: max(1680px, 70dvw);
      margin: 0 auto;
    }
    flex: 1;
    mat-card.mat-mdc-card.admin-card {
      border: none;
      background-color: mat.get-theme-color($primus-m3, surface-container);
    }
    .header-and-description-wrapper {
      padding: 1.4rem 2rem 2rem 2rem;
    }
    .header-and-description-wrapper > header {
      margin-top: 0;
    }
  }

  app-admin-concept-lists {
    section.header-and-description-wrapper {
      padding: 2rem 0 0 2rem;
    }
  }

  section.header-and-description-wrapper > article {
    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 0;
    flex-direction: column;
  }

  section.header-and-description-wrapper .admin-row {
    margin-left: 0;
    gap: .5rem;
    padding-bottom: .5rem;
  }

  section.header-and-description-wrapper .admin-column {
    margin: 0;
  }

  div.admin-concept-lists__content {
    min-width: 0;
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  div.admin-concept-lists__top-content {
  flex: 0;
  padding-top: .25rem;
  }

  div.admin-concept-lists__main-content.admin-concept-lists__main-content-v2-override {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    overflow: hidden;
  }

  div.admin-concept-lists__concept-list-container {
    width: unset;
    max-width: 300px;
    flex: 1;
  }

  div.admin-concept-lists__search-view-concept-container {
    display: flex;
    flex: 1;
    min-width: 300px;

  }

  app-admin-list {
    flex: 1;
    height: unset;
    min-width: 0;
    display: flex;
    min-height: 0;
    overflow: auto;
  }

  .admin-concept-lists__content h5 {
    font-size: 18px;
    color: var(--sys-on-surface);
  }

  div.concept-list-selector {
    margin-right: .5rem;
  }

  app-admin-create-new {
    overflow: auto;
  }

  .admin-agent {
    height: unset;
    flex: 1;
    display: flex;
    min-width: 0;
    min-height: 0;
  }

  app-admin-agent {

    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 0;

    section.header-and-description-wrapper > article {
      overflow: hidden;
    }

    .admin-concept-concepts__title-container {
      padding-right: 20px;
    }
  }

  app-admin-user {

    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 0;

    section.header-and-description-wrapper > article {
      overflow: hidden;
    }

    .admin-concept-concepts__title-container {
      padding-right: 20px;
    }
  }

  app-admin-template-model {
    flex: 1 1 auto;
  }

  .admin-action-toolbar {
    height: unset;
    width: unset;
    position: static;
  }

  img.profile-image,
  .none-profile-image {
    font-size: 3rem;
    height: 3rem;
    width: 3rem;
    color: var(--sys-outline-variant);
  }

  .user-profile-logout {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .admin-information {
    &--new-layout {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.5rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      min-width: 40%;
    }

    &__item-header {
      font: mat.get-theme-typography($primus-m3, label-medium, font);
      letter-spacing: mat.get-theme-typography($primus-m3, label-medium, letter-spacing);
    }

    &__item-value {
      font: mat.get-theme-typography($primus-m3, body-medium, font);
      letter-spacing: mat.get-theme-typography($primus-m3, body-medium, letter-spacing);
    }
  }

  ///////////////////// SUPER SPECIAL MEDIA QUERY OVERRIDES
  @media (orientation: portrait) and (max-width: 960px) {
    .admin-header-container {
      left: 0;
      top: 62px;
    }

    .floating-button-container {
      bottom: 7.5rem;
    }
  }
}

.report-picture-thumbnail img {
  height: 250px;
  object-fit: contain;
}

.report-artifact-image-img {
  max-height: 100%;
  object-fit: contain;
}
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
